import Head from 'next/head';

function Site() {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>David Herzina</title>
      </Head>
      <div className="Container">
        <div className="Header">
          <h1 className="Header__title">
            David <strong>Herzina</strong>
          </h1>
          <p className="Header__lead">
            detail―oriented web & iOS developer
            <br />
            with a sense for design
          </p>
        </div>
        <div className="Sections">
          <div className="Column">
            <div className="Section">
              <h2>Contact Information</h2>
              <a href="mailto:me@davidherzina.com" className="Section__link">
                me@davidherzina.com
              </a>
              <a href="tel:+420777227355" className="Section__link">
                +420&nbsp;777&nbsp;227&nbsp;355
              </a>

              <div className="Section__muted">
                <a href="https://github.com/davidherzina" target="_blank" className="Section__link" rel="noreferrer">
                  Github: davidherzina
                </a>
                <a href="https://twitter.com/davidherzina" target="_blank" className="Section__link" rel="noreferrer">
                  Twitter: davidherzina
                </a>
                <br />
                <div className="Section__line">IČO: 04427220</div>
                <address className="Section__address">
                  <a href="https://goo.gl/maps/zxhB7nMENJoJ3GVG8" target="_blank" rel="noreferrer">
                    Děčín, Czech Republic
                  </a>
                </address>
              </div>
            </div>
            <div className="Section">
              <h2>Tools & Technologies</h2>
              <p className="Section__line">
                Swift, Objective-C, PHP, Javascript, SCSS, Jetbrains, Xcode, Docker, Nanobox, Zeplin, MySQL, Nette,
                React
              </p>
            </div>
          </div>
          <div className="Column Column--large">
            <div className="Section">
              <h2>Web Applications & Sites</h2>
              <a href="https://idecin.cz" target="_blank" className="Section__link" rel="noreferrer">
                <strong>iDěčín</strong> <span className="Section__link--muted">― Děčín city visitors portal</span>
              </a>
              <a href="https://googlephotos.forcandies.com" target="_blank" className="Section__link" rel="noreferrer">
                <strong>Google Photos</strong> <span className="Section__link--muted">― Unofficial desktop client</span>
              </a>
              <a
                href="https://www.kr-ustecky.cz/aplikace-na-chytre-parkovani-anebo-platforma-pro-zapojeni-obcanu-mesta-do-tvorby-smart-city-programatori-pres-vikend-vylepsovali-decin/d-1722382"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>Děčín 2.1</strong> <span className="Section__link--muted">― Result of 48 hours hackathon</span>
              </a>
              <a
                href="https://www.czechcrunch.cz/2017/01/pixbuf-ceska-sluzba-ktera-umoznuje-efektivni-spravu-fotografii-s-rychlym-uploadem-na-socialni-site/"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>Pixbuf</strong>{' '}
                <span className="Section__link--muted">― social media tool for photographers</span>
              </a>
              <a href="https://www.iconsfeed.com/" target="_blank" className="Section__link" rel="noreferrer">
                <strong>Iconsfeed</strong> <span className="Section__link--muted">― iOS icons gallery</span>
              </a>
            </div>
            <div className="Section">
              <h2>iOS & macOS Applications</h2>
              <a href="https://fingy.app/" target="_blank" className="Section__link" rel="noreferrer">
                <strong>Fingy</strong>{' '}
                <span className="Section__link--muted">― Hangboard trainings in your pocket</span>
              </a>
              <a href="https://svatkoid.forcandies.com" target="_blank" className="Section__link" rel="noreferrer">
                <strong>Svátkoid</strong> <span className="Section__link--muted">― ultimate namedays app</span>
              </a>
              <a
                href="https://itunes.apple.com/us/app/nesn%C4%9Bzeno-cz/id1460586515?l=cs&ls=1&mt=8"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>Nesnězeno</strong>{' '}
                <span className="Section__link--muted">― mobile client for Nesnězeno.cz</span>
              </a>
              <a
                href="https://itunes.apple.com/app/dejkafe-cz/id1435295710?&mt=8"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>Dejkafe</strong> <span className="Section__link--muted">― mobile client for Dejkafe.cz</span>
              </a>
              <a
                href="https://itunes.apple.com/sk/app/benefitpro/id1447099096?l=sk&mt=8"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>BenefitPro</strong>{' '}
                <span className="Section__link--muted">― mobile client for BenefitPro.sk</span>
              </a>
              <a
                href="https://itunes.apple.com/us/app/pixbuf/id1317757766?ls=1&mt=12"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>Pixbuf for macOS</strong>{' '}
                <span className="Section__link--muted">― desktop client for Pixbuf</span>
              </a>
              <a
                href="https://itunes.apple.com/us/app/pixbuf/id1084322171?&mt=8"
                target="_blank"
                className="Section__link"
                rel="noreferrer"
              >
                <strong>Pixbuf for iOS</strong> <span className="Section__link--muted">― mobile client for Pixbuf</span>
              </a>
            </div>
          </div>
        </div>
        <a href="https://forcandies.com" target="_blank" className="Card" rel="noreferrer">
          Now I create awesome stuff <strong>forCandies</strong>
        </a>
      </div>

      <div className="Photo">
        <img src="/static/photo.jpg" alt="David Herzina" />
      </div>
    </>
  );
}

export default Site;
